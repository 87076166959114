import React from "react"
import { graphql, navigate } from "gatsby"
import Img from "gatsby-image"

import Layout from "../layout"
import SEO from "../seo"
import { blogPostBodySerializer } from "../_helpers/blogPostBodySerializer"
import CustomLink from "../customLink"

const CaseStudy = ({ data }) => {
  if (!data.case_study) return null

  let caseStudy = data.case_study.data
  // preview support
  if (typeof window !== 'undefined' && window.__PRISMIC_PREVIEW_DATA__ && window.__PRISMIC_PREVIEW_DATA__.prismicCaseStudy) {
    caseStudy = window.__PRISMIC_PREVIEW_DATA__.prismicCaseStudy.data
  }
  
  let customer = caseStudy.customer.document.data
  if (typeof window !== 'undefined' && caseStudy.body.length === 0) {
    navigate(`/customers/#${data.case_study.uid}`, {replace: true})
  }
  
  return (
    <Layout mainClass="case-study-detail">
      <SEO title={`Case Study - ${caseStudy.headline.text}`} />

      <section className="case-study-header">
        <div className="inner narrow">
          <div className="logo" data-sal="slide-up" data-sal-delay="150">
            <Img fluid={customer.customer_logo.fluid} alt={customer.customer_name.text} />
          </div>
          
          <h3 data-sal="slide-up" data-sal-delay="300">{caseStudy.headline.text}</h3>
        </div>
      </section>

      <div className="case-study-slices">
        {caseStudy.body.map((sliceData, ind) => {
          return <Slice data={sliceData} ind={ind} key={ind} />
        })}
      </div>
    </Layout>
  )
}

export default CaseStudy

// slice support
function Slice({ data }) {
  if (!data) return null

  switch (data.__typename) {
    case 'PrismicCaseStudyBodyFullImage':
      return <FullImage data={data.primary} />
    case 'PrismicCaseStudyBodyText':
      return <Text data={data.primary} />
    case 'PrismicCaseStudyBodyFullQuote':
      return <FullQuote data={data.primary} />
    case 'PrismicCaseStudyBodyTextImageLeft':
      return <TextImageLeft data={data.primary} />
    case 'PrismicCaseStudyBodyTextImageRight':
      return <TextImageRight data={data.primary} />
    case 'PrismicCaseStudyBodyTextQuoteLeft':
      return <TextQuoteLeft data={data.primary} />
    case 'PrismicCaseStudyBodyTextQuoteRight':
      return <TextQuoteRight data={data.primary} />
    case 'PrismicCaseStudyBodyCta':
      return <Cta data={data.primary} />
    default:
      return null
  }
}

// case study slice types
const FullImage = ({ data }) => {
  return (
    <div className="case-study-slice full-image" data-sal="slide-up" data-sal-delay="300">
      <div className="inner wide">
        <figure>
          <Img fluid={data.image.fluid} alt={data.image.alt} imgStyle={{ objectFit: "cover" }} />
          <figcaption>{data.image.alt}</figcaption>
        </figure>
      </div>
    </div>
  )
}

const Text = ({ data }) => {
  return (
    <div className="case-study-slice text" data-sal="slide-up" data-sal-delay="300">
      <div className="inner narrow">
        <div className="body" dangerouslySetInnerHTML={{ __html: blogPostBodySerializer(data.text.html) }}></div>
      </div>
    </div>
  )
}

const FullQuote = ({ data }) => {
  return (
    <div className="case-study-slice full-quote" data-sal="slide-up" data-sal-delay="300">
      <div className="inner narrow">
        <div className="body" dangerouslySetInnerHTML={{ __html: blogPostBodySerializer(data.quote.html) }}></div>

        {data.author &&
          <p className="quote-author">{data.author.text}</p>
        }

        {data.author_title &&
          <p className="quote-author-title">{data.author_title.text}</p>
        }
      </div>
    </div>
  )
}

const TextImageLeft = ({ data }) => {
  return (
    <div className="case-study-slice text-image-left" data-sal="slide-up" data-sal-delay="300">
      <div className="inner">
        <div className="col asset">
          <figure>
            <Img fluid={data.image.fluid} alt={data.image.alt} />
            <figcaption>{data.image.alt}</figcaption>
          </figure>
        </div>

        <div className="col text">
          <div className="body" dangerouslySetInnerHTML={{ __html: blogPostBodySerializer(data.text.html) }}></div>
        </div>
      </div>
    </div>
  )
}

const TextImageRight = ({ data }) => {
  return (
    <div className="case-study-slice text-image-right" data-sal="slide-up" data-sal-delay="300">
      <div className="inner">
        <div className="col text">
          <div className="body" dangerouslySetInnerHTML={{ __html: blogPostBodySerializer(data.text.html) }}></div>
        </div>

        <div className="col asset">
          <figure>
            <Img fluid={data.image.fluid} alt={data.image.alt} />
            <figcaption>{data.image.alt}</figcaption>
          </figure>
        </div>
      </div>
    </div>
  )
}

const TextQuoteLeft = ({ data }) => {
  return (
    <div className="case-study-slice text-quote-left" data-sal="slide-up" data-sal-delay="300">
      <div className="inner">
        <div className="col text quote">
          <div className="body" dangerouslySetInnerHTML={{ __html: blogPostBodySerializer(data.quote.html) }}></div>
          {data.author &&
            <p className="quote-author">{data.author.text}</p>
          }

          {data.author_title &&
            <p className="quote-author-title">{data.author_title.text}</p>
          }
        </div>

        <div className="col text">
          <div className="body" dangerouslySetInnerHTML={{ __html: blogPostBodySerializer(data.text.html) }}></div>
        </div>
      </div>
    </div>
  )
}

const TextQuoteRight = ({ data }) => {
  return (
    <div className="case-study-slice text-quote-right" data-sal="slide-up" data-sal-delay="300">
      <div className="inner">
        <div className="col text">
          <div className="body" dangerouslySetInnerHTML={{ __html: blogPostBodySerializer(data.text.html) }}></div>

          {data.author &&
            <p className="quote-author">{data.author.text}</p>
          }

          {data.author_title &&
            <p className="quote-author-title">{data.author_title.text}</p>
          }
        </div>

        <div className="col text quote">
          <div className="body" dangerouslySetInnerHTML={{ __html: blogPostBodySerializer(data.quote.html) }}></div>
        </div>
      </div>
    </div>
  )
}

const Cta = ({ data }) => {
  return (
    <div className="case-study-slice cta" data-sal="slide-up" data-sal-delay="300">
      <div className="inner">
        <h3>{data.text.text}</h3>
        <CustomLink link={data.link} className="button violet" target={data.link.target}>{data.link_text.text}</CustomLink>
      </div>
    </div>
  )
}

// TODO: add other type supports

export const pageQuery = graphql`
  query CaseStudyQuery($uid: String!) {
    case_study: prismicCaseStudy(uid: {eq: $uid}) {
      uid
      first_publication_date
      last_publication_date
      data {
        customer {
          document {
            ... on PrismicCustomer {
              id
              data {
                customer_logo {
                  fluid(maxHeight: 60) {
                    ...GatsbyPrismicImageFluid
                  }
                }
                customer_name {
                  html
                  text
                }
              }
            }
          }
        }
        headline {
          text
        }
        body {
          __typename
          ... on PrismicCaseStudyBodyFullImage {
            primary {
              image {
                fluid(maxWidth: 1600, maxHeight: 750) {
                  ...GatsbyPrismicImageFluid
                }
                alt
              }
            }
          }
          ... on PrismicCaseStudyBodyText {
            primary {
              text {
                html
                text
              }
            }
          }
          ... on PrismicCaseStudyBodyFullQuote {
            primary {
              quote {
                html
                text
              }
              author {
                html
                text
              }
              author_title {
                html
                text
              }
            }
          }
          ... on PrismicCaseStudyBodyTextImageLeft {
            primary {
              text {
                html
                text
              }
              image {
                fluid(maxWidth: 571, maxHeight: 571) {
                  ...GatsbyPrismicImageFluid
                }
                alt
              }
            }
          }
          ... on PrismicCaseStudyBodyTextImageRight {
            primary {
              text {
                html
                text
              }
              image {
                fluid(maxWidth: 571, maxHeight: 571) {
                  ...GatsbyPrismicImageFluid
                }
                alt
              }
            }
          }
          ... on PrismicCaseStudyBodyTextQuoteLeft {
            primary {
              quote {
                html
                text
              }
              author {
                html
                text
              }
              author_title {
                html
                text
              }
              text {
                html
                text
              }
            }
          }
          ... on PrismicCaseStudyBodyTextQuoteRight {
            primary {
              quote {
                html
                text
              }
              author {
                html
                text
              }
              author_title {
                html
                text
              }
              text {
                html
                text
              }
            }
          }
          ... on PrismicCaseStudyBodyCta {
            primary {
              text {
                html
                text
              }
              link {
                url
                type
                target
              }
              link_text {
                text
                html
              }
            }
          }
        }
      }
    }
  }
`